import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import { letters } from '../../data';

const Letters = () => {
  const title = "Students' letters and replies";

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>

      {letters.map((_, index) => (
        <span key={index}>
          <a href={`#${index + 1}`}>{index + 1}</a>{' '}
          {index + 1 < letters.length && '| '}
        </span>
      ))}

      {letters.map((letter, index) => (
        <div key={index}>
          <hr />

          <h2 id={index + 1}>{index + 1}</h2>

          {letter.content}
          <blockquote>{letter.reply}</blockquote>
        </div>
      ))}
    </Layout>
  );
};

export default Letters;
